import { derived, type Readable, type Writable, writable } from 'svelte/store';
import type { FilterType, IFilters } from './types';

export const getDefaultFilters = (): IFilters => ({
  courseType: [],
  score: undefined,
  category: [],
  lessionMode: [],
  duration: [],
  priceMin: 0,
  priceMax: 30000,
  studentType: [],
  month: [],
  credits: undefined,
  certificationReleaseEntity: [],
});

export class FilterStore {
  private static instance: FilterStore;

  private _fetchingData: Writable<boolean>;

  private _courseTypeFacets: Writable<any>;
  private _categoryFacets: Writable<any>;
  private _lessionModeFacets: Writable<any>;
  private _durationFacets: Writable<any>;
  private _studentTypeFacets: Writable<any>;
  private _monthFacets: Writable<any>;
  private _creditsFacets: Writable<any>;
  private _certificationReleaseEntityFacets: Writable<any>;

  public searchStore: Writable<string> = writable('');
  public currentTyped: Writable<string> = writable('');
  public suggestedSearch: Writable<string[]> = writable([]);

  public filterStore: Writable<IFilters> = writable(getDefaultFilters());
  public clearfilterStore: Writable<IFilters> = writable(getDefaultFilters());
  public filtersApplied: Readable<{ [key in FilterType]: boolean }> = derived(
    this.filterStore,
    ($store) => {
      const defaultFilters = getDefaultFilters();
      return {
        courseType: !FilterStore.isEqual($store.courseType, defaultFilters.courseType),
        score: $store.score !== defaultFilters.score,
        category: !FilterStore.isEqual($store.category, defaultFilters.category),
        lessionMode: !FilterStore.isEqual($store.lessionMode, defaultFilters.lessionMode),
        duration: !FilterStore.isEqual($store.duration, defaultFilters.duration),
        priceMin: $store.priceMin != defaultFilters.priceMin,
        priceMax: $store.priceMax != defaultFilters.priceMax,
        studentType: !FilterStore.isEqual($store.studentType, defaultFilters.studentType),
        month: !FilterStore.isEqual($store.month, defaultFilters.month),
        credits: $store.credits !== defaultFilters.credits,
        certificationReleaseEntity: !FilterStore.isEqual(
          $store.certificationReleaseEntity,
          defaultFilters.certificationReleaseEntity
        ),
      };
    }
  );
  public isAnyFilterApplied: Readable<boolean> = derived(this.filtersApplied, ($store) => {
    for (let filter in $store) {
      if ($store[filter]) return true;
    }
    return false;
  });

  public static getInstance() {
    if (!FilterStore.instance) {
      FilterStore.instance = new FilterStore();
    }
    return FilterStore.instance;
  }

  public setCourseTypeFacets(facets: any) {
    this._courseTypeFacets.set(facets?.course_type_full_slug || null);
  }

  public setCategoryFacets(facets: any) {
    this._categoryFacets.set(facets?.category_slug || null);
  }

  public setLessionModeFacets(facets: any) {
    this._lessionModeFacets.set(facets?.lession_mode_slug || null);
  }

  public setDurationFacets(facets: any) {
    this._durationFacets.set(facets?.duration_slug || null);
  }

  public setMonthFacets(facets: any) {
    this._monthFacets.set(facets?.month || null);
  }

  public setCreditsFacets(facets: any) {
    this._creditsFacets.set(facets?.credits || null);
  }

  public setStudentTypeFacets(facets: any) {
    this._studentTypeFacets.set(facets?.student_type || null);
  }

  public setCertificationReleaseEntityFacets(facets: any) {
    this._certificationReleaseEntityFacets.set(facets?.certification_release_entity || null);
  }

  public removeAllFilters() {
    this.filterStore.set(getDefaultFilters());
  }

  public removeSearchText() {
    this.searchStore.set('');
  }

  public setFilters(filters: IFilters) {
    this.filterStore.set(filters);
  }

  public setFetchingData(fetching: boolean) {
    this._fetchingData.set(fetching);
  }

  public get isFetching() {
    return derived(this._fetchingData, (value) => value);
  }

  public get courseTypeFacets() {
    return derived(this._courseTypeFacets, (value) => value);
  }

  public get categoryFacets() {
    return derived(this._categoryFacets, (value) => value);
  }

  public get lessionModeFacets() {
    return derived(this._lessionModeFacets, (value) => value);
  }

  public get durationFacets() {
    return derived(this._durationFacets, (value) => value);
  }

  public get studentTypeFacets() {
    return derived(this._studentTypeFacets, (value) => value);
  }

  public get monthFacets() {
    return derived(this._monthFacets, (value) => value);
  }

  public get creditsFacets() {
    return derived(this._creditsFacets, (value) => value);
  }

  public get certificationReleaseEntityFacets() {
    return derived(this._certificationReleaseEntityFacets, (value) => value);
  }

  private constructor() {
    this._courseTypeFacets = writable(null);
    this._categoryFacets = writable(null);
    this._lessionModeFacets = writable(null);
    this._durationFacets = writable(null);
    this._studentTypeFacets = writable(null);
    this._monthFacets = writable(null);
    this._creditsFacets = writable(null);
    this._certificationReleaseEntityFacets = writable(null);
    this._fetchingData = writable(false);
  }

  public static isEqual(array1: (number | string)[], array2: (number | string)[]) {
    return (
      array1.length === array2.length && array1.every((value, index) => value === array2[index])
    );
  }

  // reset all filters
  public resetFilters() {
    this.filterStore.set(getDefaultFilters());
  }
}
